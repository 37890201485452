<template>
	<Page
		background-color="rgba(69, 15, 95, 0.6)"
		poster="https://delivery.eventcdn.net/events/638/b5301970-648f-4f59-b2e3-bcb279e91f0e/4906Party_Ocean_Lawn_Sparkle1_PosterImage.png"
		background-video-loop="https://delivery.eventcdn.net/events/638/b5301970-648f-4f59-b2e3-bcb279e91f0e/4907Party_Ocean_Lawn_Sparkle1.mp4"
	>
		<v-container fluid class="px-4 px-md-8" style="height: 90vh">
			<v-row style="height: 100%">
				<v-col
					style="height: 100%"
					class="d-flex flex-column align-center justify-center"
				>
					<div
						class="d-flex justify-center align-center"
						style="gap: 1rem"
					>
						<v-img
							contain
							width="61px"
							src="https://delivery.eventcdn.net/events/638/b5301970-648f-4f59-b2e3-bcb279e91f0e/4908Pandora_Monogram2x_BmxHnhF.png"
						></v-img>
						<div>
							<span
								class="font-weight-bold"
								style="
									text-align: left;
									font: normal normal normal 30px Pan Display;
									font-weight: 400;
									letter-spacing: 0px;
									color: #ffcad4;
									text-shadow: 0px 3px 12px #37094d;
									opacity: 0.95;
								"
								>MARCH 28, 2022, 1:00PM EST</span
							>
							<br />
							<span
								style="
									text-align: left;
									font: normal normal normal 52px Pan Display;
									letter-spacing: 0px;
									color: #ffcad4;
									text-shadow: 0px 3px 12px #37094d;
									opacity: 1;
								"
								>SPRING 2022 CONFERENCE</span
							>
						</div>
					</div>

					<lh-countdown
						:time="time"
						value-class="value-class"
						unit-class="unit-class"
						:labels="{
							days: 'Days',
							hours: 'Hours',
							minutes: 'Minutes',
							seconds: 'Seconds',
						}"
					></lh-countdown>
				</v-col>
			</v-row>
		</v-container>
	</Page>
</template>

<script>
export default {
	data() {
		return {
			time: "2022-03-28T17:00:00Z",
		};
	},
};
</script>

<style lang="scss">
.livehouse-app.v-application {
	.value-class {
		font-size: 140px !important;
		line-height: 140px !important;
		text-align: center;
		font: normal normal normal 140px Pan Display;
		letter-spacing: 0px;
		color: #ffcad4;
		text-shadow: 0px 6px 20px #270836;
		opacity: 1;
		letter-spacing: 5.2px !important;
		font-weight: bold !important;
	}

	.unit-class {
		text-align: center;
		font: normal normal normal 140px Pan Display;
		letter-spacing: 0px;
		color: #ffcad4;
		text-shadow: 0px 6px 20px #270836;
		opacity: 1;
		font-size: 25px !important;
	}
}
</style>